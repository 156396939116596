import Swiper from "swiper";
import SwiperCore, { Navigation, Controller, Autoplay } from "swiper/core";

SwiperCore.use([Navigation, Controller, Autoplay]);

(function () {
  const resHeaderSlider = document.querySelector(".responsibility-header");
  const resTableSlider = document.querySelector(".responsibility-table");
  if (!resHeaderSlider || !resTableSlider) {
    return;
  }

  // breakpoint where swiper will be destroyed
  // and switches to a dual-column layout
  const breakpoint = window.matchMedia("(min-width:767px)");
  // keep track of swiper instances to destroy later
  let mySwiper;
  let mySwiper2;
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  const breakpointChecker = function () {
    // if larger viewport and multi-row layout needed
    if (breakpoint.matches === true) {
      // clean up old instances and inline styles when available
      if (mySwiper !== undefined) mySwiper.destroy(true, true);
      if (mySwiper2 !== undefined) mySwiper2.destroy(true, true);
      // or/and do nothing
      return;
      // else if a small viewport and single column layout needed
    } else if (breakpoint.matches === false) {
      // fire small viewport version of swiper
      return enableSwiper();
    }
  };
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  const enableSwiper = function () {
    mySwiper = new Swiper(".responsibility-header", {
      loop: true,
      slidesPerView: 1,
      spaceBetween: "30%",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
    mySwiper2 = new Swiper(".responsibility-table", {
      loop: true,
      slidesPerView: 1,
      spaceBetween: "30%",
      controller: {
        control: mySwiper,
      },
    });
    mySwiper.controller.control = mySwiper2;
  };
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
  // keep an eye on viewport size changes
  breakpoint.addEventListener("change", breakpointChecker);
  // kickstart
  breakpointChecker();
})();
